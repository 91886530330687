<template>
  <q-page padding>
    <header-campaign />
    <list-campaign />
    <form-campaign v-if="dialogCampaign" />
  </q-page>
</template>

<script>
import HeaderCampaign from '../../components/campaign/HeaderCampaign.vue';
import FormCampaign from '../../components/campaign/FormCampaign.vue';
import ListCampaign from '../../components/campaign/ListCampaign.vue';
import CampaignMixin from '../../mixins/CampaignMixin';

export default {
  components: { ListCampaign, FormCampaign, HeaderCampaign },
  name: 'Campaigns',
  mixins: [CampaignMixin],
  props: {
    id: {
      type: [String, Number]
    }
  }
};
</script>
